import React, { FunctionComponent, useCallback, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import Text from "../shared/text";
const EventGallery: FunctionComponent<{ gallery: any, eventName: string, eventDate: string }> = ({ gallery, eventName, eventDate }) => {

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    return (
        <>  <div className="" >

            <Text textType="header" className="text-bytestrone-blue text-center lg:text-left" > {eventName} </Text>
            <Text textType="contentL" className="text-bytestrone-green text-center lg:text-left font-bold  " > {eventDate} </Text>
            <Gallery photos={gallery} direction={"row"} margin={10} onClick={openLightbox} />
            <ModalGateway>
                {viewerIsOpen ?
                    <Modal onClose={closeLightbox} allowFullscreen={true} >
                        <Carousel
                            currentIndex={currentImage}
                            views={gallery.map(x => ({
                                ...x,
                                srcset: x.srcSet
                            }))}
                        />
                    </Modal>
                    : null}
            </ModalGateway>
        </div>
        </>
    )
}

export default EventGallery;