import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Section from "../components/shared/section/sectionWrapper";
import Text from "../components/shared/text";
import EventGallery from "../components/events/events";

const Events: FunctionComponent = () => {
    const eventsQuery = useStaticQuery(
        graphql`
        query EventQuery {
            allEventsJson {
                edges {
                    node {
                    eventName
                    date
                    gallery {
                      height
                      src
                      width
                    }
                }
            }
        }     
    }
    `);

    return (<>
        <Section className="bg-white lg:pr-10 " >
            <div className="flex flex-col mt-5 w-full h-full  md:py-10 py-20 space-y-4" >
                <Text className="text-center text-bytestrone-blue lg:text-right whitespace-nowrap" textType="headerL" >
                    Moments at Bytestrone
                </Text>


                {
                    eventsQuery.allEventsJson.edges.map((item, index) => {
                        const gallery = item.node.gallery;
                        return <div className="" key={index} >
                            {/* <Text textType="header" className="text-bytestrone-blue text-center lg:text-left" > {item.node.eventName} </Text> */}
                            {/* <Text textType="contentL" className="text-bytestrone-green text-center lg:text-left font-bold  " > {item.node.date} </Text> */}

                            <EventGallery gallery={gallery} eventName={item.node.eventName} eventDate={item.node.date} ></EventGallery>
                        </div>
                    })}
            </div>
            {/* <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/91BUM3WhCfo?start=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
        </Section>
    </>)
}
export default Events;